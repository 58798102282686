import React from 'react';
import './Styles.scss'; 

const DocumentPage = () => {
    return (
        <div className="document-page">
          <div className="card-container">
            <div className="background-rectangle"></div>
            <div className="card">
              <div className="icon-circle"></div>
              <h3>Zero Investment</h3>
              <p>We take care of asset-related expenses, zero liability for you</p>
            </div>
            <div className="card">
              <div className="icon-circle"></div>
              <h3>Medical Insurance</h3>
              <p>Get medical insurance coverage of Rs. 1 lakh</p>
            </div>
            <div className="card">
              <div className="icon-circle"></div>
              <h3>Ease of Work</h3>
              <p>Enjoy flexible working hours that will fit your schedule</p>
            </div>
            <div className="card">
              <div className="icon-circle"></div>
              <h3>Weekly Payouts</h3>
              <p>Experience stable earnings with our fixed earnings & incentives</p>
            </div>
            <div className="card">
              <div className="icon-circle"></div>
              <h3>Zero Ownership Cost</h3>
              <p>Zero down payments, start driving without any down payments</p>
            </div>
            <div className="card">
              <div className="icon-circle"></div>
              <h3>Get Support 24x7</h3>
              <p>If there is anything you need, you can reach us anytime</p>
            </div>
          </div>
    
          <div className="required-documents">
            <h2>Required Documents</h2>
            <p>After receiving the required documents, we conduct a thorough background verification prior to onboarding.</p>
            <div className="document-container">
              <div className="document-card">
                <div className="document-number">1</div>
                <p>Valid Driving Licenses</p>
              </div>
              <div className="document-card">
                <div className="document-number">2</div>
                <p>Aadhar Card</p>
              </div>
              <div className="document-card">
                <div className="document-number">3</div>
                <p>Current & Permanent Address Proof</p>
              </div>
              <div className="document-card">
                <div className="document-number">4</div>
                <p>One Reference Details</p>
              </div>
              <div className="document-card">
                <div className="document-number">5</div>
                <p>Bank Details</p>
              </div>
            </div>
          </div>
        </div>
      );
    };

export default DocumentPage;

import React, { useState } from 'react';
import emailjs from 'emailjs-com';
import './Styles.scss';

const ApplyToDriveModal = ({ isOpen, onClose }) => {
  const [formData, setFormData] = useState({
    name: '',
    mobile: '+91',
    email: '',
    city: 'Bangalore' // Prefilled and disabled
  });
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [mobileError, setMobileError] = useState('');
  const [emailError, setEmailError] = useState('');

  // Function to reset the form and submission state
  const resetForm = () => {
    setFormData({
      name: '',
      mobile: '+91',
      email: '',
      city: 'Bangalore'
    });
    setIsSubmitted(false);
    setMobileError('');
    setEmailError('');
  };

  // Handle close modal and reset state
  const handleClose = () => {
    resetForm();
    onClose();
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    // Mobile number validation
    if (name === 'mobile') {
      const phoneRegex = /^\+91\d{10}$/;
      setMobileError(!phoneRegex.test(value) ? 'Invalid mobile number' : '');
    }

    // Email validation
    if (name === 'email') {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      setEmailError(!emailRegex.test(value) ? 'Invalid email address' : '');
    }

    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!mobileError && !emailError) {
      emailjs.send('service_kfs83yt', 'template_ldpytxh', formData, 'NZX-WCxlcMW3SRvta')
        .then(() => {
          setIsSubmitted(true);
        })
        .catch(error => {
          console.error('EmailJS Error:', error);
        });
    }
  };

  if (!isOpen) return null;

  return (
    <div className='apply-to-drive'>
      <div className="modal-overlay">
        <div className="modal-content">
          <div className="modal-header">
            <h2>Grin Mobility</h2>
            <button className="close-button" onClick={handleClose}>✕</button>
          </div>
          <div className="modal-body">
            {!isSubmitted ? (
              <form onSubmit={handleSubmit} className="modal-form">
                <input
                  type="text"
                  name="name"
                  placeholder="Name"
                  value={formData.name}
                  onChange={handleChange}
                  required
                  aria-label="Name"
                  className="modal-input"
                />
                <input
                  type="text"
                  name="mobile"
                  placeholder="Mobile Number (+91)"
                  value={formData.mobile}
                  onChange={handleChange}
                  required
                  aria-label="Mobile"
                  className={`modal-input ${mobileError ? 'error' : ''}`}
                />
                {mobileError && <p className="error-message">{mobileError}</p>}
                <input
                  type="email"
                  name="email"
                  placeholder="Email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                  aria-label="Email"
                  className={`modal-input ${emailError ? 'error' : ''}`}
                />
                {emailError && <p className="error-message">{emailError}</p>}
                <input
                  type="text"
                  name="city"
                  value={formData.city}
                  disabled
                  className="modal-input"
                />
                <button
                  type="submit"
                  className="submit-button"
                  disabled={!formData.name || mobileError || emailError || !formData.email}
                >
                  Submit
                </button>
              </form>
            ) : (
              <p className="thank-you-message">
                Thank you for applying with Grin Mobility! We will contact you soon.
              </p>
            )}
          </div>
          <div className="modal-footer">
            <button className="footer-button" onClick={handleClose}>
              {isSubmitted ? 'Close' : 'Cancel'}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ApplyToDriveModal;

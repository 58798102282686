import React, { useState } from "react";
import GoogleMapSection from "../GoogleMapSection";
import SearchSection from "../SearchSection";
import { SourceContext } from "../../../../Context/SourceContext";
import { DestinationContext } from "../../../../Context/DestinationContext";

const MapPage = ({ setCarDistance ,setUserRideType,userRideType}) => {
  const [source, setSource] = useState({ address: "", coordinates: {} });
  const [destination, setDestination] = useState({ address: "", coordinates: {} });
  
  const [rideType, setRideType] = useState(""); // Ensure ride type management is maintained

  return (
    <SourceContext.Provider value={{ source, setSource }}>
      <DestinationContext.Provider value={{ destination, setDestination }}>
        <div className="map-page-container py-4 md:py-8 px-4 md:px-8">
          {/* For mobile: Stack vertically, For desktop: 2-column grid */}
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div className="search-section">
              <SearchSection
                setRideType={setRideType}
                rideType={rideType}
                setCarDistance={setCarDistance}
                setUserRideType={setUserRideType} 
                userRideType={userRideType}
              />
            </div>
            <div className="google-map-section">
              <GoogleMapSection userRideType={rideType} />
            </div>
          </div>
        </div>
      </DestinationContext.Provider>
    </SourceContext.Provider>
  );
};

export default MapPage;


// import React, { useState } from "react";
// import GoogleMapSection from "../GoogleMapSection";
// import SearchSection from "../SearchSection";
// import { SourceContext } from "../../../../Context/SourceContext";
// import { DestinationContext } from "../../../../Context/DestinationContext";


// function MapPage({ setCarDistance ,setUserRideType,userRideType}) {


//   const [source, setSource] = useState(null);
//   const [destination, setDestination] = useState(null);
//   const [rideType, setRideType] = useState("");


//   return (
//     <SourceContext.Provider value={{ source, setSource, }}>
//       <DestinationContext.Provider value={{ destination, setDestination }}>
//         <div className="map-page-container py-4 md:py-8 px-4 md:px-8">
//           {/* Grid layout for desktop, full-width for mobile */}
//           <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
//             <div className="search-section">
            
//           <div>
//             <SearchSection setCarDistance={setCarDistance} setRideType={setRideType} rideType={rideType} setUserRideType={setUserRideType} />
//           </div>

//             </div>
//             <div className="google-map-section">
//               <GoogleMapSection rideType={rideType} userRideType={userRideType}/>
//             </div>
//           </div>
//         </div>
//       </DestinationContext.Provider>
//     </SourceContext.Provider>
//   );
// };

// export default MapPage;

import React, { useState } from 'react';
import './Styles.scss';
import { ellipse, driver } from '../../Assets/Icons';
import DocumentsPage from './Components';
import ApplyToDriveModal from '../../Components/ApplyToDriveModal';

const DriveWithUs = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModal = () => setIsModalOpen(true);
  const handleCloseModal = () => setIsModalOpen(false);

  return (
    <div className="drive-with-us-container">
      <section className="top-section">
        <div className="text-content">
          <h1>Be a part of our family</h1>
          <h2>Drive with us</h2>
          <button className="apply-button" onClick={handleOpenModal}>Apply to Drive</button>
        </div>
        <div className="image-placeholder">
          <img src={driver} alt="driver" />
        </div>
      </section>

      <section className="why-us-section">
        <img src={ellipse} alt="Ellipse" className="ellipse-bg" />
        <h2>Why with us?</h2>
        <h3>What’s your benefit?</h3>
        <p>Drive with Grin mobility and be the driver of real change!</p>
        <p>Behind the wheel of emission-free EVs,</p>
        <p>our driver-partners play a pivotal role in creating a sustainable future.</p>
        <p>Enjoy the perks of no asset ownership, weekly payouts & enticing incentives.</p>
      </section>

      <DocumentsPage/>

      <ApplyToDriveModal isOpen={isModalOpen} onClose={handleCloseModal} />
    </div>
  );
};

export default DriveWithUs;

import React, { useState, useCallback, useEffect, useContext } from "react";
import {
  GoogleMap,
  MarkerF,
  OverlayViewF,
  DirectionsRenderer,
} from "@react-google-maps/api";
import { SourceContext } from "../../../../Context/SourceContext";
import { DestinationContext } from "../../../../Context/DestinationContext";

function GoogleMapSection({ userRideType }) {
  // Updated map style with fixed height for better appearance on mobile
  const containerStyle = {
    width: "100%",
    height: "475px", // A smaller height for better mobile view
    borderRadius: "15px",
    overflow: "hidden",
  };

  const { source } = useContext(SourceContext);
  const { destination } = useContext(DestinationContext);

  const [center, setCenter] = useState({
    lat: -3.745,
    lng: -38.523,
  });
  const [map, setMap] = useState(null);
  const [directionRoutePoint, setDirectionRoutePoint] = useState(null);
  const [currentLocation, setCurrentLocation] = useState(null);

  const isValidLatLng = (coords) => {
    return coords && typeof coords.lat === "number" && typeof coords.lng === "number";
  };

  const directionRoute = useCallback(() => {
    if (!source || !destination || !window.google || !isValidLatLng(source) || !isValidLatLng(destination)) return;

    const DirectionsService = new window.google.maps.DirectionsService();
    DirectionsService.route(
      {
        origin: { lat: source.lat, lng: source.lng },
        destination: { lat: destination.lat, lng: destination.lng },
        travelMode: window.google.maps.TravelMode.DRIVING,
      },
      (result, status) => {
        if (status === window.google.maps.DirectionsStatus.OK) {
          setDirectionRoutePoint(result);
        } else {
          console.error("Error loading route:", status);
        }
      }
    );
  }, [source, destination]);

  useEffect(() => {
    if (source && isValidLatLng(source) && map) {
      map.panTo({ lat: source.lat, lng: source.lng });
      setCenter({ lat: source.lat, lng: source.lng });
    }
    if (destination && isValidLatLng(destination) && source) {
      directionRoute();
    }
  }, [source, map, directionRoute, destination]);

  useEffect(() => {
    if (userRideType === "Airport Rides" && source) {
      setCenter({ lat: source.lat, lng: source.lng });
    } else if (userRideType === "City Rides" || userRideType === "Rental Rides") {
      if (!source && !destination && currentLocation) {
        setCenter({ lat: currentLocation.lat, lng: currentLocation.lng });
      }
      setDirectionRoutePoint(null);
    }
  }, [userRideType, source, destination, currentLocation]);

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setCurrentLocation({ lat: latitude, lng: longitude });
          setCenter({ lat: latitude, lng: longitude });
        },
        (error) => console.error("Error getting location:", error),
        { enableHighAccuracy: true, timeout: 5000, maximumAge: 0 }
      );
    }
  }, []);

  const onLoad = useCallback((map) => {
    setMap(map);
  }, []);

  const onUnmount = useCallback(() => {
    setMap(null);
  }, []);

  return (
    <div className="relative w-full h-full">
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={center}
        zoom={10}
        onLoad={onLoad}
        onUnmount={onUnmount}
      >
        {currentLocation && (
          <MarkerF
            position={currentLocation}
            icon={{
              scaledSize: new window.google.maps.Size(25, 25),
            }}
          />
        )}
        {source && (
          <MarkerF
            position={{ lat: source.lat, lng: source.lng }}
            icon={{
              scaledSize: new window.google.maps.Size(20, 20),
            }}
          >
            <OverlayViewF
              position={{ lat: source.lat, lng: source.lng }}
              mapPaneName={OverlayViewF.OVERLAY_MOUSE_TARGET}
            >
              <div className="p-2 bg-white font-bold inline-block rounded-lg shadow-md">
                <p className="text-black text-[16px]">{source.label}</p>
              </div>
            </OverlayViewF>
          </MarkerF>
        )}
        {destination && (
          <MarkerF
            position={{ lat: destination.lat, lng: destination.lng }}
            icon={{
              scaledSize: new window.google.maps.Size(20, 20),
            }}
          >
            <OverlayViewF
              position={{ lat: destination.lat, lng: destination.lng }}
              mapPaneName={OverlayViewF.OVERLAY_MOUSE_TARGET}
            >
              <div className="p-2 bg-white font-bold inline-block rounded-lg shadow-md">
                <p className="text-black text-[16px]">{destination.label}</p>
              </div>
            </OverlayViewF>
          </MarkerF>
        )}
        {directionRoutePoint && <DirectionsRenderer directions={directionRoutePoint} />}
      </GoogleMap>
    </div>
  );
}

export default GoogleMapSection;
